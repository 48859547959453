import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Product from '../components/Product'
import Search from '../components/Search'
import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";
import { UserSession } from "../auth/session.connect";
import { GolbalContext } from "../App";

const ProductDetails = () => {
  // get the product id from url
  const { id } = useParams();
  const { addToCart } = useContext(CartContext);
  const { products } = useContext(ProductContext);
  const { search } = useContext(GolbalContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const encodedURLIS = encodeURIComponent(window.location.href);
    if(!UserSession) {
      window.location = `/signin?redirect=${encodedURLIS}`;
    }
  }, []);


  const filteredProducts = products?.filter((item) => {
    return item?.name?.toLowerCase().includes(search.toLowerCase()) 
    || 
    item?.description?.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <section className="py-20">
        <div className="container mx-auto">
          <Search 
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 lg:mx-8 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
            {filteredProducts?.map((product) => {
              return (
                <Product product={product} key={product.id}/>
              );
            })}
          </div>
        </div>
      </section>
  );
};

export default ProductDetails;
