import React, { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Product from "../auth/product.connect";
import { AdminUsernameSession } from "../auth/session.connect";

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {

  const [products, setProducts] = useState([]);
  const connectProduct = new Product();
  
  useEffect(() => {
    const fetchProducts = async () => {
      const response = await connectProduct.getAdminProducts(10);
      setProducts(response?.data);
    };
    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
