import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AdminSession } from "../auth/session.connect";

const Hero = () => {
  const imageUrl = AdminSession && JSON.parse(AdminSession).image_url ? JSON.parse(AdminSession).image_url : "";

  const backgroundStyle = imageUrl
    ? { backgroundImage: `url(${imageUrl})` }
    : {};

  return (
    <section
      style={backgroundStyle}
      className={`h-[800px] ${"bg-hero"} bg-no-repeat bg-cover bg-center py-20`}
    >
      <Helmet>
        <title>@{AdminSession ? JSON.parse(AdminSession)?.company_name : "Dabinx"}</title>
        <meta
          name="description"
          content={`Check out @${AdminSession ? JSON.parse(AdminSession)?.company_name : "Dabinx"} - ${AdminSession ? JSON.parse(AdminSession)?.description : "Welcome to Dabinx"}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="container mx-auto flex justify-around h-full">
        {/* text */}
        <div className="flex flex-col justify-center">
          <div className="font-semibold flex items-center uppercase">
            <div className="w-10 h-[2px] mr-3 bg-cyan-700"></div>
            @{AdminSession ? JSON.parse(AdminSession)?.company_name : "Dabinx"}
          </div>
          <h1 className="uppercase text-[55px] md:text-[70px] leading-[1.1] font-semibold mb-4">
            {AdminSession ? JSON.parse(AdminSession)?.description : "Welcome to Dabinx"}<br />
          </h1>
          <Link to={'/products'} className='self-start uppercase font-semibold border-b-2 border-primary'>Browse Products</Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
