import { AxiosConnect  } from "./axios.connect";

export default class Order {
    constructor() {}

    async orderProduct(data) {
        try {
            const { data: response } = await AxiosConnect.post("/user/make-order", data);
            if(response && response?.data){
                console.log({ response });
                return response
            }else{
                return null;
            }
        } catch (error) {
            console.log({ error })
        }
    }

    async orderSingleProduct(data) {
        try { 
            // /order-single-product-stripe
            // /order-single-product
            const { data: response } = await AxiosConnect.post("/user/order-single-product", data);
            if(response && response?.data){
                console.log({ response });
                return response
            }else{
                return null;
            }
        } catch (error) {
            console.log({ error })
        }
    }

    async orderSingleProductNGN(data) {
        try { 
            const { data: response } = await AxiosConnect.post("/user/order-single-product", data);
            if(response && response?.data){
                console.log({ response });
                return response
            }else{
                return null;
            }
        } catch (error) {
            console.log({ error })
        }
    }

    async orderSingleProductUSD(data) {
        try { 
            // /order-single-product-stripe
            // /order-single-product
            const { data: response } = await AxiosConnect.post("/user/order-single-product-stripe", data);
            if(response && response?.data){
                console.log({ response });
                return response
            }else{
                return null;
            }
        } catch (error) {
            console.log({ error })
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        if(errorMessage == "An issue has arisen. Please try again later."){
            errorMessage = "Please ensure that you are a waitlist member and use the email to send us a message"
        }
        return errorMessage;
    }
}