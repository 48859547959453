import React, { useContext, useEffect } from "react";
import { DabinxContext } from "../contexts/DabinxProductContext";
import Product from '../components/Product'
import Hero from '../components/Hero'
import { useParams } from "react-router-dom";
import { AdminUsernameSession, UserSession, saveAdmin } from "../auth/session.connect";

const Dabinx = () => {


  const { products } = useContext(DabinxContext);
  const filteredProducts =  products?.filter((item) => {
    return (
      item
    );
  }) || [];

  useEffect(() => {
    if(!UserSession) {
      window.location = "/signin";
    }
  }, []);

  return (
    <div>
      <Hero />
      {/* <section className="py-20">
        <div className="container mx-auto">
          <h1 className="text-3xl font-semibold mb-10 text-center">Explore Our Products</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 lg:mx-8 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
            {filteredProducts.map((product) => {
              return (
                <Product product={product} key={product.product_id}/>
              );
            })}
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Dabinx;
