import { AxiosConnect } from "./axios.connect";

export default class Cart {
  constructor() {}

  async addToCart(data) {
    try {
      console.log({ data });
      const { data: response } = await AxiosConnect.post(
        "/user/add-user-cart",
        data
      );
      if (response && response?.data) {
        console.log({ response });
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  }

  async getCart() {
    try {
      const { data: response } = await AxiosConnect.get("/user/user-cart");
      if (response && response?.data) {
        console.log({ response });
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  }

  async deleteFromCart(cart_id, product_id) {
    try {
      const { data: response } = await AxiosConnect.get(
        `/user/remove-user-cart/${cart_id}/${product_id}`
      );
      if (response && response?.data) {
        console.log({ response });
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  }

  async decrementQuantityInCart(cart_id, product_id) {
    try {
      const { data: response } = await AxiosConnect.get(
        `/user/remove-user-cart-product/${cart_id}/${product_id}`
      );
      if (response && response?.data) {
        console.log({ response });
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  }

  async deleteAllCartItem(cart_id) {
    try {
      const { data: response } = await AxiosConnect.get(`/user/dlt-all-cart/${cart_id}`);
      if (response && response?.data) {
        console.log({ response });
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  }

  handleTheError(AxiosError) {
    let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
    console.error('Error message:', errorMessage);
    if(errorMessage == "An issue has arisen. Please try again later."){
        errorMessage = "Please ensure that you are a waitlist member and use the email to send us a message"
    }
    return errorMessage;
}
}
