import { AxiosConnect } from "./axios.connect";
import { AdminUsernameSession } from "./session.connect";


export default class Product {
    constructor() {}

    async getAdminDetails(username) {
        try {
            const { data: response } = await AxiosConnect.get(`/admin/admin-details/${username}`);
            if(response && response?.data){
                console.log({ response });
                return response?.data;
            } else {
                console.log("Unable to complete request.");
                return null;
            }
        } catch (error) {
            console.log({ error })
        }
    }

    async getAdminProducts(limit) {
        try {
            const username = AdminUsernameSession;
            console.log({ username })
            if(username) {
            const { admin_id } = await this.getAdminDetails(username);
            console.log({ admin_id })
            const { data: response } = await AxiosConnect.get(`/product/product-by-admin/${admin_id}/${limit}`);
            if(response && response?.data){
                console.log({ response });
                return response;
            } else {
                console.log("Unable to complete request.");
                return null;
            }
            }
            
        } catch (error) {
            console.log({ error })
        }
    }

    async getDabinxAdminProducts(limit) {
        try {
            const { data: response } = await AxiosConnect.get(`/product/product-by-admin/${limit}`);
            if(response && response?.data){
                console.log({ response });
                return response;
            } else {
                console.log("Unable to complete request.");
                return null;
            }
        } catch (error) {
            console.log({ error })
        }
    }
    
    async getProductDetails(id) {
        try {
            const { data: response } = await AxiosConnect.post(`/product/product-details/${id}`);
            if(response && response?.data){
                console.log({ response });
                return response;
            } else {
                console.log("Unable to complete request.");
                return null;
            }
        } catch (error) {
            console.log({ error })
        }
    }

    async productReviews(product_id) {
        try {
            const { data: response } = await AxiosConnect.get(`/product/product-reviews/${product_id}`);
            if(response && response?.data) {
                console.log({ response });
                return response;
            } else {
                console.log("Unable to complete request.");
                return null;
            }
        } catch (error) {
            console.log({ error });
        }
    }

    async postProductReviews(data_) {
            const { data: response } = await AxiosConnect.post(`/product/product-review-manual`, data_);
            if(response && response?.data) {
                console.log({ response });
                return response;
            } else {
                console.log("Unable to complete request.");
                return null;
            }
    }

    async getDeliveryMethod( product_id ) {
        const { data: response } = await AxiosConnect.get(`/product/get-delivery/${product_id}`);
        if(response && response?.data) {
            console.log({ response });
            return response;
        } else {
            console.log("Unable to complete request.");
            return null;
        }
    }

    async allowPickUpDelivery(product_id) {}

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        if(errorMessage == "An issue has arisen. Please try again later."){
            errorMessage = "Please ensure that you are a waitlist member and use the email to send us a message"
        }
        return errorMessage;
    }
    
}