import axios from "axios";
import { UserSession } from "./session.connect";
import configs from "../config";
const user = JSON.parse(UserSession);

export const AxiosConnect = axios.create({
    baseURL: configs.ENDPOINT,
    headers: {
      Authorization: `${user?.token || ""}`
    }
});


// LOCAL => http://localhost:4001/
// LIVE => https://dabinx-backend-production-a908.up.railway.app/