import { AxiosConnect } from "./axios.connect";


export default class Auth {
    constructor() {}

    async signUp(data) {
      try {
        const {
            email,
            password,
            currency,
            phone_number
        } = data;
        const { data: response } = await AxiosConnect.post("/user/register",{
            email,
            password,
            currency,
            phone_number
        });
        if(response){
            console.log({ response });
            return response;
        }
      } catch (error) {
        const msg = this.handleTheError(error) || "Unable to complete request.";
        console.log({ msg });
        alert(msg)
      }
    }

    async signIn(data) {
        try {
            const { data: response } = await AxiosConnect.post("/user/login",data);
            if(response){
                console.log({ response });
                return response;
            }
          } catch (error) {
            const msg = this.handleTheError(error) || "Unable to complete request.";
            console.log({ msg });
            alert(msg)
          }
    }

    async verifyEmailPIN({ user_id, token }) {
        try {
            const { data: response } = await AxiosConnect.post("/user/v-registration",{
                user_id,
                token
            });

            if(response && response?.data){
                console.log({ response });
                return response;
            } else{
                console.log("Unable to complete request.");
                return null;
            }

        } catch (error) {
            console.log({ error })
        }
    }

    async resendEmailPIN(user_id) {
        try {
            const { data: response } = await AxiosConnect.post("/user/r-registration",{
                user_id
            });

            if(response && response?.data){
                console.log({ response });
                return response;
            } else{
                console.log("Unable to complete request.");
                return null;
            }

        } catch (error) {
            console.log({ error })
        }
    }

    async forgotPassword(email) {
        try {
            const { data: response } = await AxiosConnect.post("/user/forgot-password",{
                email
            });

            if(response && response?.data){
                console.log({ response });
                return response;
            } else{
                console.log("Unable to complete request.");
                return null;
            }

        } catch (error) {
            console.log({ error })
        }
    }

    async verifyForgotPasswordPIN(email, token, password) {
        try {
            const { data: response } = await AxiosConnect.post("/user/v-forgot-password",{
                email, token, password
            });

            if(response && response?.data){
                console.log({ response });
                return response;
            } else{
                console.log("Unable to complete request.");
                return null;
            }

        } catch (error) {
            console.log({ error })
        }
    }

    async resendForgotPasswordPIN(email) {
        try {
            const { data: response } = await AxiosConnect.post("/user/r-forgot-password",{
                email
            });

            if(response && response?.data){
                console.log({ response });
                return response;
            } else{
                console.log("Unable to complete request.");
                return null;
            }

        } catch (error) {
            console.log({ error })
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        if(errorMessage == "An issue has arisen. Please try again later."){
            errorMessage = "Please ensure that you are a waitlist member and use the email to send us a message"
        }
        return errorMessage;
    }
}