import React, { useState, useEffect, useContext } from 'react';
import { GolbalContext } from '../App';

const Search = () => {
  const { search, setSearch } = useContext(GolbalContext);
  const handleSearch = (event) => {
    event.preventDefault();
    // Add your search functionality here
    console.log('Performing search...');
  };

  return (
    <form className="m-4 z-40 mb-20" onSubmit={handleSearch}>
      <div className="flex">
        
        <div className="relative w-full">
          <input
            type="search"
            id="search-dropdown"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="block p-2.5 w-full z-20 text-sm text-primary bg-none rounded-sm border border-gray-300"
            placeholder="Type to search products..."
            required
          />
        </div>
      </div>
    </form>
  );
};

export default Search;
