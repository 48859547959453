import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "../contexts/ProductContext";
import Product from "../components/Product";
import Hero from "../components/Hero";
import { CartContext } from "../contexts/CartContext";
import { SidebarContext } from "../contexts/SidebarContext";
import States_ from "../contexts/States.json";
import { useParams } from "react-router-dom";
import { AdminSession, UserSession } from "../auth/session.connect";
import { DabinxContext } from "../contexts/DabinxProductContext";
import Order from "../auth/order.connect";
import ProductConnect from "../auth/product.connect";

const SingleCheckout = () => {
  const { product_id } = useParams();
  const [tax, setTax] = useState(500);
  const [totalAmount, setTotalAmount] = useState(0);
  const { products } = useContext(ProductContext);
  const [ zip, setZip ] = useState("");
  const { products: dabinx_products } = useContext(DabinxContext);
  const [ selected_country, setSelected_country ] = useState("Nigeria");
  const { handleClose } = useContext(SidebarContext);
  const product =
    products?.find((item) => {
      return item.product_id === product_id;
    }) ||
    dabinx_products?.find((item) => {
      return item.product_id === product_id;
    });

    const [ shipping_address, setShipping_address ] = useState("");
    const [ email_, setEmail_ ] = useState("");
    const [ billing_address, setBilling_address ] = useState("");
    const [ state, setState ] = useState("State");
    const [ phone_number, setPhone_number ] = useState("");
    const [ subTax, setSubTax ] = useState(0);
    const [ btn, setBtn ] = useState("Place Order");
    const [ disablebtn, setDisablebtn ] = useState(false);
    const [ country, setCountry ] = useState([ "Nigeria" ]);
    const [ delivery, setDelivery ] = useState({});
    const [ charge, setCharge ] = useState(0);
    const pr = new ProductConnect();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  const makeOrder = async (e) => {
        e.preventDefault();
        try{

        if(!phone_number || (phone_number).length <= 7) {
            alert("Please input the right Phone Number.");
         } else if(!shipping_address || (shipping_address).length <= 5) {
          alert("Please input a valid shipping address.");
         } else {
          const use_email = (JSON.parse(UserSession))?.email || email_;
          console.log({ use_email });
          setDisablebtn(true);
          const body = {
            product_id,
            admin_id: (JSON.parse(AdminSession))?.admin_id,
            email: use_email,
            phone_number,
            shipping_address: state.concat(", "+ shipping_address + "," + zip),
            billing_address: state.concat(", " + shipping_address + "," + zip),
            subTax: charge,
            quantity: 1
          };
    
          setBtn("Saving Order ...");
          setBtn("Please wait ...");  
          const result = await new Order().orderSingleProduct(body);
          setBtn("Creating Payment link");
          const paymentService = result?.data?.link_data;
          const currency =  paymentService?.currency;
          let authorization_url;
          authorization_url = result?.data.link_data?.authorization_url;
          if(currency?.toLowerCase() == "usd"){
            authorization_url = result?.data.link_data?.url;
          }
          console.log({ authorization_url: authorization_url });
          setBtn("Redirecting to PayStack...");
          setDisablebtn(false);

          if(authorization_url) {
            window.location = authorization_url;
          } else {
            alert("Please make sure all details are provided.");
          }
          setBtn("Place Order");
        }
        }
        catch( error ) {
          const msg = new Order().handleTheError(error) || "Can't complete request.";
          alert(msg);
          setDisablebtn(false);
        }
  
  }

  const getDeliveryMethod = async (product_id) => {
    try{
      const { data: response } = await pr.getDeliveryMethod(product_id?.product_id);
      if(response) {
        if(response?.dynamic) {
          setDelivery(response?.delivery);
        } else {
          console.log(response);
        }
      }
    } catch(error) {
       const msg = pr.handleTheError(error) || "Unable to complete request.";
       console.log({ delivery_msg: msg })      
    }
  }

  const detectCharge = (country, state) => {
    const chargeForState = delivery && delivery?.states_delivery_charge?.find((x) => x. country == country );
    const charge = chargeForState?.states?.find((el) => el.state == state );

    if(charge || delivery?.default_charge){
      const c_ = charge?.charge || delivery?.default_charge || 0;
      setCharge(c_);
    }

  }

  useEffect(() => {
    console.log({ delivery })
    if(delivery?.states_delivery_charge){
      detectCharge(selected_country, state);
    }
  },[state, selected_country]);

  const encodedURLIS = encodeURIComponent(window.location.href);


  useEffect(() => {
    setTotalAmount((product?.amount || 0) + tax);
    getDeliveryMethod(product);
    setCharge(delivery?.default_charge || 0);
  }, [tax, product]);

  if (!product) {
    return (
      <section className="h-screen flex justify-center items-center">
        Loading...
      </section>
    );
  }

  const { name, description, images, image, amount, currency } = product;

  return (
    <div>


      <div class="grid mt-12 sm:mb-8 sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div class="px-4 pt-8">
          <p class="text-xl font-medium">Order Summary</p>
          <p class="text-gray-400">
            Check your items. And select a suitable shipping method.
          </p>

          <div class="mt-8 space-y-3 rounded-none border bg-white px-2 py-4 sm:px-6">
            <div
              key={product_id}
              class="flex flex-col rounded-none bg-white sm:flex-row"
            >
             <img
                class="m-2 h-24 w-28 rounded-md border object-cover object-center"
                src={images[0]}
                alt={name}
              />
              <div class="flex w-full flex-col px-4 py-4">
                <span class="font-semibold">{name}</span>
                <span class="float-right text-gray-400">{description}</span>
                <p class="text-lg font-bold">
                  {currency} {(amount).toLocaleString()}
                </p>
              </div>
            </div>
          </div>

          <p class="mt-8 text-lg font-medium">Shipping Methods</p>
          <span className="text-sm text-center text-gray-700">Home delivery is available after purchase.
             We will contact you to confirm if you need delivery and provide the cost.
             </span>
          <form class="mt-5 grid gap-6">
            <div class="relative">
              <input
                class="peer hidden"
                id="radio_1"
                type="radio"
                name="radio"
                checked
              />
              <span class="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
              <label
                class="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-none border border-gray-300 p-4"
                for="radio_1"
              >
                {/* <img
                  class="w-14 object-contain"
                  src="/images/naorrAeygcJzX0SyNI4Y0.png"
                  alt=""
                /> */}
                <div class="ml-5">
                  <span class="mt-2 font-semibold">PickUp Delivery
                  <span className="pt-4 pb-4 p-2 mb-1">
                   <span className="bg-gray-400 text-white text-xs rounded-full px-3 py-1 font-semibold">default</span>
                   </span>
                  </span>
                  <p class="text-slate-500 text-sm leading-6">
                    Delivery: 2-4 Days
                  </p>
                </div>
              </label>
            </div>

          </form>

          <p class="mt-8 text-lg font-medium">Payment Methods</p>
          <form class="mt-5 grid gap-6">
            <div class="relative">
              <input
                class="peer hidden"
                id="radio_1"
                type="radio"
                name="radio"
                checked
              />
              <span class="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
              <label
                class="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-none border border-gray-300 p-4"
                for="radio_1"
              >
                <div class="ml-5">
                  <span class="mt-2 font-semibold"> PayStack 
                  <span className="pt-4 pb-4 p-2 mb-1">
                   <span className="bg-gray-400 text-white text-xs rounded-full px-3 py-1 font-semibold">default</span>
                   </span>
                  </span>
                  <p class="text-slate-500 text-sm leading-6">
                    Instant with: Card, transfer & USSD
                  </p>
                </div>
              </label>
            </div>

          </form>
        </div>

        <div class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p class="text-xl font-medium">Payment Details</p>
          <p class="text-gray-400">
            Complete your order by providing your payment details.
          </p>
          <div class="">
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              {!UserSession && (
                <>
                 <label for="phone_number" class="mt-4 mb-2 block text-sm font-medium">
                 Email, <a href={`/signin?redirect=${encodedURLIS}`} className="text-blue-400 cursor-pointer">or login ?</a>
               </label>
               <div class="relative">
                 <input
                   type="text"
                   id="email"
                   name="email"
                   class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                   placeholder="Enter email"
                   value={email_}
                   onChange={(e) => setEmail_(e.target.value)}
                 />
                 </div>
                 </>
              )}
            </label>
            <label for="phone_number" class="mt-4 mb-2 block text-sm font-medium">
              Phone Number
            </label>
            <div class="relative">
              <input
                type="number"
                id="phone_number"
                name="phone_number"
                class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Phone Number"
                value={phone_number}
                onChange={(e) => setPhone_number(e.target.value)}
              />
              </div>

            <label for="billing-address" class="mt-4 mb-2 block text-sm font-medium">
              Shipping Address
            </label>
            <div class="relative mb-2">
              <input
                type="text"
                id="billing-address"
                name="billing-address"
                class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Street Address"
                value={shipping_address}
                onChange={(e) => setShipping_address(e.target.value)}
              />
              </div>


            <div class="flex flex-col sm:flex-row">

              <select
                type="text"
                name="billing-state"
                class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                onChange={(e) => {
                    const value = e.target?.value;
                    setState(value);
                }}
              >
                <option value={selected_country}
                >{selected_country}</option>
                { delivery && country
                .filter(state => {
                  return !delivery.filteredStates || !delivery.filteredStates.some(countryObj => countryObj?.country?.includes(state));
                })
                .map(x=>
                <option value={x}>{ x }</option>
                )}
              </select>

              <select
                type="text"
                name="billing-state"
                class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                onChange={(e) => {
                    const value = e.target?.value;
                    setState(value);
                }}
              >
                <option value={state}
                >{state}</option>
               {delivery && States_
                  .filter(state => {
                    return !delivery.filteredStates || !delivery.filteredStates.some(countryObj => countryObj?.states?.includes(state));
                  })
                  .map(x =>
                    <option key={x} value={x}>{x}</option>
                  )}

              </select>

              <input
                type="text"
                name="billing-zip"
                class="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="ZIP"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div>

            <div class="mt-6 border-t border-b py-2">
              <div class="flex items-center justify-between">
                <p class="text-sm font-medium text-gray-900">Subtotal</p>
                <p class="font-semibold text-gray-900">
                  {currency} {(amount || 0).toLocaleString()}
                </p>
              </div>
              <div class="flex items-center justify-between">
                <p class="text-sm font-medium text-gray-900">Shipping tax</p>
                <p class="font-semibold text-gray-900">
                  {currency} { Number(tax) + Number(charge) }
                </p>
              </div>
            </div>

            <div class="mt-6 flex items-center justify-between">
              <p class="text-sm font-medium text-gray-900">Total</p>
              <p class="text-2xl font-semibold text-gray-900">
                {currency} {(totalAmount || 0).toLocaleString()}
              </p>
            </div>
          </div>
          <button
          disabled={disablebtn}
          onClick={async(e) => await makeOrder(e)}
          class="mt-4 mb-8 w-full rounded-none bg-primary px-6 py-3 font-medium text-white">
           { btn }
          </button>
        </div>
      </div>

    </div>
  );
};

export default SingleCheckout;
