import React, { useEffect } from "react";
import { useState } from "react";
import Auth from "../auth/auth.connect";
import { UserSession } from "../auth/session.connect";
import { AppNameOrigin } from "../app.config";

function Register() {
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ phone_number, setPhone_number ] = useState("");
    const [ btn, setBtn ] = useState("Sign Up")
    const auth = new Auth();
    const [ redirectValue, setRedirectValue ] = useState(null);
    const [ disabled, setDisabled ] = useState(false);

    useEffect(() => {
    window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectParam = searchParams.get('redirect');
      setRedirectValue(redirectParam !== null ? encodeURIComponent(redirectParam): null);
      console.log({ redirectParam });
    }, []);

    const SignUp = async (e) => {
      e.preventDefault();
      setDisabled(true);
      try {
        setBtn("Signing up");
        const result = await  auth.signUp({
            email,
            password,
            currency:"NGN",
            phone_number
        });
        setDisabled(false);
        if(!result) {
          alert("An error occured. Please login again.");   
          setBtn("Try Again")
        } else {
          alert(result?.message);
          sessionStorage.setItem("dabinx-user", JSON.stringify(result?.data));
          setBtn("Done");
          if(redirectValue){
            window.location = decodeURIComponent(redirectValue);
          }
          alert("Account created");
        }
      } catch (error) {
        setBtn("Sign Up")
        const msg = auth.handleTheError(error) || "Unable to process request";
        alert(msg);
        setDisabled(false);
      }
    }

  return (
    <div>
      <section class="bg-gray-100 dark:bg-white">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-black"
          >
            <h1 className="uppercase font-bold text-3xl text-black"> { 
            // AppNameOrigin
             } </h1>
          </a>
          
          <div class="w-full bg-white rounded-none shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-black">
                Create Account
              </h1>
              <div class="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent  dark:placeholder-black dark:text-black"
                    placeholder="name@gmail.com"
                    required
                  />
                </div>
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                     Phone number
                  </label>
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    value={phone_number}
                    onChange={(e) => setPhone_number(e.target.value)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent  dark:placeholder-black dark:text-black"
                    placeholder="+234..."
                    required
                  />
                </div>
                <div>
                  <label
                    for="password"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Password"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent  dark:placeholder-black dark:text-black"
                    required
                  />
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="remember"
                        class="text-gray-800 dark:text-gray-800"
                      >
                        Agree to Terms and Service
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  disabled={disabled}
                  onClick={(e) => SignUp(e)}
                  class="w-full text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-none text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  { btn }
                </button>
                <p class="text-sm font-light text-gray-800 dark:text-gray-700">
                  Have an account yet?{" "}
                  <a
                  href={redirectValue !== null ? `/signin?redirect=${redirectValue}` : "/signin" }
                    class="font-medium text-primary-600 hover:underline dark:text-gray-800"
                  >
                    Sign In
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
