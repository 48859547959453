import React, { createContext, useState, useEffect } from "react";
import Product from "../auth/product.connect";
export const DabinxContext = createContext();

const DabinxProductProvider = ({ children }) => {

  const [products, setProducts] = useState([]);
  const connectProduct = new Product();
  
  useEffect(() => {
    const fetchProducts = async () => {
      const response = await connectProduct.getDabinxAdminProducts(10);
      console.log({ response })
      setProducts(response?.data);
    };
    fetchProducts();
  }, []);

  return (
    <DabinxContext.Provider value={{ products }}>
      {children}
    </DabinxContext.Provider>
  );
};

export default DabinxProductProvider;
