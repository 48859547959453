import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Product from "./pages/Product";
import Checkout from "./pages/Checkout";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./pages/Register";
import LoginPage from "./pages/LoginPage";
import VerificationPage from "./pages/VerificationPage";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Profile from "./pages/Profile";
import Dabinx from "./pages/Dabinxhome";
import SingleCheckout from "./pages/SingleCheckout";
import { UserSession } from "./auth/session.connect";
import AdminProductDetails from "./pages/AdminProductDetails";
export const GolbalContext = createContext({});

const App = () => {
  const [ search, setSearch ] = useState("");


  return (
    <div className="overflow-hidden">
      <GolbalContext.Provider
        value={{ search, setSearch }}
      >
      <Router>
        <Header />
        <Routes>
          <Route path="/:admin_id" element={<Home />}></Route>
          <Route path="/" element={<Dabinx />}></Route>
          <Route path="/product/:id" element={<ProductDetails />}></Route>
          <Route path="/product/:admin_id/:id" element={<AdminProductDetails />}></Route>
          <Route path="/products" element={<Product />}></Route>
          <Route path="/checkout" element={<Checkout />}></Route>
          <Route path="/checkout/:product_id" element={<SingleCheckout />}></Route>
          <Route path="/signin" element={<LoginPage />}></Route>
          <Route path="/signup" element={<Register />}></Route>
          <Route path="/verify-email" element={<VerificationPage />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/verify-forgot-password" element={<ChangePassword />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
        </Routes>
        <Sidebar />
        <Footer />
      </Router>
      </GolbalContext.Provider>
    </div>
  );
};

export default App;
