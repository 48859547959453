import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { CartContext } from "../contexts/CartContext";
import { Link } from "react-router-dom";
import Logo from "../img/logo.svg";
import { BsBag } from "react-icons/bs";
import { AdminSession } from "../auth/session.connect";
import { GolbalContext } from "../App";

const Header = () => {
  const { codeClasses } = useContext(CartContext);
  const [ isActive, setIsActive ] = useState(false);
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const { itemAmount } = useContext(CartContext);

  // event listener
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  return (
    <header
      className={`${
        isActive ? "bg-white py-4 shadow-md" : "bg-none py-6"
      } fixed w-full z-10 lg:px-8 transition-all`}
    >
      <div className="container mx-auto flex items-center justify-between h-full">
        <Link to={`/@${AdminSession ? JSON.parse(AdminSession)?.company_name : "Dabinx"}`}>
          <div className="w-[140px]">
            <p className=" font-semibold uppercase flex"> {AdminSession ? JSON.parse(AdminSession)?.company_name : "Dabinx"} </p>
          </div>
        </Link>

        {/* cart */}
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="cursor-pointer flex relative"
        >
          <BsBag className="text-2xl" />
          <div className={codeClasses}>
            {itemAmount}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
