import React, { useState } from "react";
import Auth from "../auth/auth.connect";
import { useEffect } from "react";

function ChangePassword() {
  const [ email, setEmail ] = useState("");
  const [ token, setToken ] = useState("");
  const [ new_password, setNew_password ] = useState("");
  const [ btn, setBtn ] = useState("Confirm");

  const auth = new Auth();

  const [ redirectValue, setRedirectValue ] = useState(null);
    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectParam = searchParams.get('redirect');
      setRedirectValue(redirectParam !== null ? encodeURIComponent(redirectParam): null);
      console.log({ redirectParam });
    }, []);

  const forgotPassword = async (e) => {
    e.preventDefault();
    setBtn("Making request...");
    const result = await auth.verifyForgotPasswordPIN(email, token, new_password);

    if(!result) {
      alert("Unable to Complete request.");
      setBtn("Try again")
    } else {
      alert(result?.message);
      setBtn("Confirm");
      window.location = decodeURIComponent(redirectValue) || "/signin";
    }
  }

  return (
    <div>
      <section class="bg-gray-50 dark:bg-white">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-black"
          >
            <h1 className="uppercase font-bold text-3xl text-black"> Dabinx </h1>
          </a>
          <div class="w-full bg-white rounded-none shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Please enter a new password
              </h1>

              <div class="space-y-4 md:space-y-6" action="#">
                
                <div>
                <label
                    for="otp"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    OTP <span className="text-black">(Check your mail )</span>
                </label>
                  <input
                    type="text"
                    name="otp"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    id="otp"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-700 dark:text-black"
                    placeholder="123456"
                    required=""
                  />
                </div>

                <div>
                <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Email
                </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-700 dark:text-black"
                    placeholder="dabin@gmail.com"
                    required=""
                  />
                </div>

                 <div>
                <label
                    for="password"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    New password
                </label>
                  <input
                    type="password"
                    name="password"
                    value={new_password}
                    onChange={(e) => setNew_password(e.target.value)}
                    id="password"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-700 dark:text-black"
                    placeholder="**********"
                    required=""
                  />
                </div>

                <button
                  onClick={(e) => forgotPassword(e)}
                  class="w-full text-white bg-black hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-none text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  { btn }
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangePassword;
