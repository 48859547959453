import Product from "./product.connect";

export const UserSession = sessionStorage.getItem("dabinx-user");
export const AdminSession = sessionStorage.getItem("dabinx-admin");
export const AdminUsernameSession = sessionStorage.getItem("dabinx-admin-username");
export const CartId = sessionStorage.getItem("dabinx-user-cart-id");
export const Cart = sessionStorage.getItem("dabinx-user-cart");

export const saveAdmin = async ({ admin_id }) => {
    if(!admin_id) {
        return null;
    } else {
        const text = removeAtSymbol(admin_id)?.trim();
        console.log({ text })
        const yes = AdminUsernameSession == text;
        console.log({ yes });
        if(AdminSession && yes ){
            return true 
        } else {
            try {
                const result = await new Product().getAdminDetails(text);
            if(!result) {
                console.log("Unable to complete request.");
                const msg = "Admin can't be found due to broken URL.";
                alert(msg);
                window.location = "/";
            } else {
                console.log("Done", admin_id);
                sessionStorage.setItem("dabinx-admin-username", text);
                sessionStorage.setItem("dabinx-admin",JSON.stringify(result));
                window.location.reload();
            }
            } catch (error) {
                const msg = new Product().handleTheError(error) || "Admin can't be found due to broken URL.";
                alert(msg);
                window.location = "/";
            }
        }

    }
}

export function removeAtSymbol(text) {
    return text.replace('@', '');
}
