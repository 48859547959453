import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";
import { SidebarContext } from "../contexts/SidebarContext";
import Reviews from "./Reviews";
import { Helmet } from "react-helmet";
import { DabinxContext } from "../contexts/DabinxProductContext";
import { useState } from "react";
import { useEffect } from "react";
import { StarIcon } from '@heroicons/react/20/solid'
import { Radio, RadioGroup } from '@headlessui/react'

const product = {
  name: 'Basic Tee 6-Pack',
  price: '$192',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Men', href: '#' },
    { id: 2, name: 'Clothing', href: '#' },
  ],
  images: [
    {
      src: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-02-secondary-product-shot.jpg',
      alt: 'Two each of gray, white, and black shirts laying flat.',
    },
    {
      src: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg',
      alt: 'Model wearing plain black basic tee.',
    },
    {
      src: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg',
      alt: 'Model wearing plain gray basic tee.',
    },
    {
      src: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-02-featured-product-shot.jpg',
      alt: 'Model wearing plain white basic tee.',
    },
  ],
  colors: [
    { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
    { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
  ],
  sizes: [
    { name: 'XXS', inStock: false },
    { name: 'XS', inStock: true },
    { name: 'S', inStock: true },
    { name: 'M', inStock: true },
    { name: 'L', inStock: true },
    { name: 'XL', inStock: true },
    { name: '2XL', inStock: true },
    { name: '3XL', inStock: true },
  ],
  description:
    'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
}

const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


const ProductDetails = () => {
  const { id } = useParams();
  const { addToCart } = useContext(CartContext);
  const { products } = useContext(ProductContext);
  const { products: dabinx_products } = useContext(DabinxContext);
  const { handleClose, setIsOpen } = useContext(SidebarContext);
  const [ index, setIndex ] = useState(0);
  const [ v_index, setV_index ] = useState(0);
  const [ amount_, setAmount_ ] = useState(null);
  const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const [selectedSize, setSelectedSize] = useState(product.sizes[2])

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const product = products?.find((item) => {
    return item.product_id === id
  }) ||
  dabinx_products?.find((item) => {
    return item.product_id === id
  });

  const handleCheckout = (product, product_id) => {
    handleClose();
    setIsOpen(false);
    window.location = `/checkout/${product_id}`;
  };

  const changeImage = (i) =>{
    setIndex(i);
  }

  const Variation_ = (amount, vi) => {
    console.log({ amount, vi })
    setAmount_(amount);
    setV_index(vi);
  }

  if (!product) {
    return (
      <section className="h-screen flex justify-center items-center">
        Loading...
      </section>
    );
  }

  // destructure product
  const { product_id, name, description, images, amount, variations, currency } = product;
  
  return (
    <div key={product_id}>
      <Helmet>
        <title>{name}</title>
        <meta
          name="description"
          content={`Check out ${name} - ${description}`}
        />
        <meta property="og:image" content={images[0]} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section className="pt-[480px] md:pt-32 pb-[400px] md:pb-12 lg:py-32 h-screen flex items-center">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="flex-1 justify-center items-center mb-8 lg:mb-0">
             <div>
             <img className="max-w-[200px] rounded-sm lg:max-w-xs" src={images[index]} alt="" />
             </div>

              <div className="flex p-4">
                { images && images?.map((img_, i) =>
                <img 
                onClick={() => changeImage(i)}
                key={i} className="h-10 w-10 mx-1 border border-black" src={img_} alt="" />
                )}
              </div>
            </div>
            <div className="flex-1 text-center lg:text-left">
              <h1 className="text-[26px] font-medium mb-2 max-w-[450px] mx-auto lg:mx-0">
                {name}
              </h1>
              <div className="text-2xl text-red-500 font-medium mb-6">
              {currency} {amount_ || amount }
              </div>

              <p className="mb-8">{description}</p>

              <div className="flex p-4 justify-center">
                { variations && variations?.map((v, i) =>
                 <span
                 key={i}
                 onClick={(e) => Variation_(v.amount || null, i)}
                 className="rounded-none cursor-pointer mx-1 text-center bg-gray-400 border border-black"
                 >
                   {v?.name}
                 </span>
                )}
              </div>

              <button
                onClick={() => addToCart(product, product_id, v_index)}
                className="bg-primary m-2 py-4 px-8 text-white"
              >
                Add to cart
              </button>
              <button
                onClick={() => handleCheckout(product, product_id)}
                className="bg-primary m-2 py-4 px-8 text-white"
              >
                Order Now
              </button>
            </div>
          </div>
        </div>
      </section>


      <div className="container items-center p-4">
       

        <Reviews 
         product_id={id}
        />
      </div>


      


      
    </div>
  );
};

export default ProductDetails;
