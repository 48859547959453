import React, { createContext, useState, useEffect, useContext } from "react";
import Cart from "../auth/cart.connect";
import { CartId, UserSession } from "../auth/session.connect";
import { GolbalContext } from "../App";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [ isGlowing, setIsGlowing ] = useState(false);

  const toggleGlow = () => {
    setIsGlowing(true);
    setTimeout(() => {
      setIsGlowing(false);
    }, 1550);
  };

  const codeClasses = `bg-red-500 absolute -right-2 -bottom-2 text-[12px] w-[18px] h-[18px] text-white rounded-full flex justify-center items-center ${
    isGlowing ? 'glow' : ''
  }`;


  const [cart, setCart] = useState(() => {
    const savedCart = sessionStorage.getItem("dabinx-user-cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    const run = async () => {
      const cart_ = await connectCart.getCart();
      console.log({ cart_: cart_ });
      sessionStorage.setItem("dabinx-user-cart-id", cart_?.data?.cart_id);
      sessionStorage.setItem("dabinx-user-cart", JSON.stringify(cart_?.data?.cart[0]?.cart_items || []));
    };
    run();
  }, [cart]);

  useEffect(() => {
    sessionStorage.setItem("dabinx-user-cart", JSON.stringify(cart));
  }, [cart]);

  const connectCart = new Cart();
  const [itemAmount, setItemAmount] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const total = cart.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.amount * currentItem.quantity;
    }, 0);
    setTotal(total);
  });

  useEffect(() => {
    if (cart) {
      const amount = cart.reduce((accumulator, currentItem) => {
        return (accumulator + currentItem.quantity) || 0;
      }, 0);
      setItemAmount(amount);
    }
  }, [cart]);


  const addToCart = async (product, id, v_index) => {
      const encodedURLIS = encodeURIComponent(window.location.href);
    try {
    if(UserSession) {
      const newItem = { ...product, quantity: 1, variation: product?.variations[v_index] };
    console.log({ newItem });
    const cartItem = cart.find((item) => {
      return item.product_id === id;
    });
    // { cart_items, totalAmount, tax, subTax, id }
    const added = await connectCart.addToCart({
      cart_items: { ...newItem, cart_id: CartId },
      totalAmount: total,
      tax: 350,
      subTax: 0,
      id,
    });
    toggleGlow();
    if (cartItem) {
      const newCart = [...cart].map((item) => {
        if (item.product_id === id) {
          return { ...item, quantity: cartItem.quantity + 1 };
        } else return item;
      });
      setCart(newCart);
    } else {
      setCart([...cart, newItem]);
    }
    } else {
       window.location = `/signin?redirect=${encodedURLIS}`;
    }
  
    } catch ( error ) {
      const msg = connectCart.handleTheError( error ) || "An error occured";
      alert(msg);
      if(msg == "Jwt expired") {
        window.location = `/signin?redirect=${encodedURLIS}`;
      }
    }
  };

  const removeFromCart = async (id) => {
    const removeProduct = await connectCart.deleteFromCart(CartId, id);
    console.log({ removeProduct });
    const newCart = cart.filter((item) => {
      return item.product_id !== id;
    });
    setCart(newCart);
  };

  const clearCart = async () => {
    const cleared = await connectCart.deleteAllCartItem(CartId);
    console.log({ cleared });
    setCart([]);
  };

  const clearCartNone = async () => {
    const cleared = await connectCart.deleteAllCartItem(CartId);
    console.log({ cleared });
    setCart([]);
  };

  const increaseAmount = (id) => {
    const cartItem = cart.find((item) => item.product_id === id);
    addToCart(cartItem, id);
  };

  const decreaseAmount = async (id) => {
    const cartItem = cart.find((item) => item.product_id === id);
    if (cartItem) {
      const dlt = await connectCart.decrementQuantityInCart(CartId, id);
      console.log({ dlt });
      const newCart = cart.map((item) => {
        if (item.product_id === id) {
          return { ...item, quantity: cartItem.quantity - 1 };
        } else {
          return item;
        }
      });
      setCart(newCart);
    }
    if (cartItem.quantity < 2) {
      await removeFromCart(id);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        clearCartNone,
        increaseAmount,
        decreaseAmount,
        itemAmount,
        total,
        codeClasses,
        toggleGlow
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
