import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";
import { SidebarContext } from "../contexts/SidebarContext";
import Reviews from "./Reviews";
import { DabinxContext } from "../contexts/DabinxProductContext";
import { AdminSession, UserSession, saveAdmin } from "../auth/session.connect";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { StarIcon } from "@heroicons/react/20/solid";
import { Radio, RadioGroup } from "@headlessui/react";


const AdminProductDetails = () => {
  const { admin_id } = useParams();

  useEffect(() => {
    if (admin_id) {
      const run = async () => {
        if (AdminSession && AdminSession !== undefined) {
          console.log(true);
          return true;
        } else {
          const save = await saveAdmin({ admin_id });
          window.location.reload();
        }
      };
      run();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const { addToCart } = useContext(CartContext);
  const { products } = useContext(ProductContext);
  const { products: dabinx_products } = useContext(DabinxContext);
  const { handleClose } = useContext(SidebarContext);
  const [index, setIndex] = useState(0);
  const [v_index, setV_index] = useState(0);
  const [amount_, setAmount_] = useState(null);
  const [choose, setChoose] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVariation, setSelectedVariation] =
    useState("Select Variation");
  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const product =
    products?.find((item) => {
      return item.product_id === id;
    }) ||
    dabinx_products?.find((item) => {
      return item.product_id === id;
    });

  const handleCheckout = (product, product_id) => {
    handleClose();
    window.location = `/checkout/${product_id}`;
  };

  const changeImage = (i) => {
    setIndex(i);
  };

  const Variation_ = (amount, vi) => {
    console.log({ amount, vi });
    setAmount_(amount);
    setV_index(vi);
  };

  const handleSelect = (v, i) => {
    setSelectedVariation(v.name);
    setIsOpen(false);
    Variation_(v?.amount || null, i);
  };

  if (!product) {
    return (
      <section className="h-screen flex justify-center items-center">
        Loading...
      </section>
    );
  }

  const {
    product_id,
    name,
    description,
    images,
    amount,
    variations,
    currency,
    allowCustomerReview,
  } = product;

  return (
    <div>
      <Helmet>
        <title>{name} - Your Website Name</title>
        <meta
          name="description"
          content={`Check out ${name} - ${description}`}
        />
        <meta property="og:image" content={images[0]} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div>
        <div className="pt-6 mt-10">
          <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
            {product && images[3] && (
              <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
                <img
                  alt={images[3]}
                  src={images[3]}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            )}

            {product && images[1] && (
              <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
                <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                  <img
                    alt={images[1]}
                    src={images[1]}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
              </div>
            )}

            {product && images[index || 0] && (
              <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
                <img
                  alt={images[index || 0]}
                  src={images[index || 0]}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            )}
          </div>

          <div className="flex mx-auto  max-w-2xl px-4 pb-4 pt-10 sm:px-6 lg:pb-14 lg:pt-6">
            {images &&
              images?.map((img_, i) => (
                <div>
                  <img
                    onClick={() => changeImage(i)}
                    key={i}
                    className="h-10 w-10 mx-1 rounded-md border border-black"
                    src={img_}
                    alt=""
                  />
                </div>
              ))}
          </div>

          {/* Product info */}
          <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-4">
            <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {name}
              </h1>
            </div>

            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900">
                {currency} {(amount_ || amount || 0).toLocaleString()}
              </p>

              {/* Reviews */}
              <div className="mt-6">
                <h3 className="sr-only">Reviews</h3>
                {!allowCustomerReview && <Reviews product_id={id} onlyStars={true} />}
              </div>

              <div className="mt-10">
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    Select Variation
                  </h3>
                  <div className="flex p-4 justify-center">
                    <div className="relative inline-block text-left">
                      <div>
                        <button
                          type="button"
                          onClick={toggleDropdown}
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                        >
                          {selectedVariation}
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </button>
                      </div>

                      {isOpen && (
                        <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                          <div className="py-1">
                            {variations &&
                              variations.map((v, i) => (
                                <span
                                  key={i}
                                  onClick={() => handleSelect(v, i)}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                                >
                                  {v?.name}
                                </span>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => handleCheckout(product, product_id)}
                  className="mt-3 flex w-full items-center justify-center rounded-sm border border-transparent bg-gray-800 px-8 py-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                >
                  Order Now
                </button>
                <button
                  onClick={() => addToCart(product, product_id, v_index)}
                  className="mt-5 flex w-full items-center justify-center rounded-sm border bg-white px-8 py-3 text-base font-medium text-black hover:bg-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 border-black"
                >
                  Add to Cart
                </button>
              </div>
            </div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
              <div>
                <h3 className="sr-only"> Description </h3>

                <div className="space-y-6">
                  <p className="text-base text-gray-900"> {description} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container items-center p-4">
          {!allowCustomerReview && <Reviews product_id={id} onlyStars={false} />}
        </div>
      </div>
    </div>
  );
};

export default AdminProductDetails;
