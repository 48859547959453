import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { IoMdAdd, IoMdClose, IoMdRemove } from "react-icons/io";

import { CartContext } from "../contexts/CartContext";

const CartItem = ({ item }) => {
  const { removeFromCart, increaseAmount, decreaseAmount } = useContext(CartContext);
  // destructure item
  const { product_id, name, currency, amount, quantity, variation } = item || [];

  return (
    <div key={product_id} className="flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full font-light text-gray-500">
      <div className="w-full min-h-[150px] flex items-center gap-x-4">
        <Link to={`/product/${product_id}`}>
          <img className="max-w-[80px]" src={item?.image || item?.images || ""} alt={name} />
        </Link>
        <div className="w-full flex flex-col">
          {/* name and remove icon */}
          <div className="flex justify-between mb-2">
            {/* name */}
            <Link
              to={`/product/${product_id}`}
              className="text-sm uppercase font-medium max-w-[240px] text-primary hover:underline"
            >
              {name} {(variation && variation?.name)?.length > 10? ((variation?.name)?.substring(0, 10) || "") : (variation?.name || "") }
            </Link>
            {/* remove icon */}
            <div
              onClick={async () => removeFromCart(product_id)}
              className="text-xl cursor-pointer"
            >
              <IoMdClose className="text-gray-500 hover:text-red-500 transition" />
            </div>
          </div>
          <div className="flex gap-x-2 h-[36px] text-sm">
            {/* quantity */}
            <div className="flex flex-1 max-w-[100px] items-center h-full border text-primary font-medium">
              <div onClick={async ()=>decreaseAmount(product_id)} className="h-full flex-1 flex justify-center items-center cursor-pointer">
                <IoMdRemove />
              </div>
              <div className="h-full flex justify-center items-center px-2">
                {(variation?.amount || amount || 0).toLocaleString()}
              </div>
              <div onClick={async () =>increaseAmount(product_id)} className="h-full flex flex-1 justify-center items-center cursor-pointer">
                <IoMdAdd />
              </div>
            </div>

            {/* item amount */}
            {/* <div className="flex flex-1 justify-around items-center">
              {currency} {(variation?.amount || amount || 0).toLocaleString()}
            </div> */}
            {/* final amount */}
            <div className="flex flex-1 justify-end items-center text-primary font-medium">{`${currency} ${parseFloat(
              (variation?.amount || amount) * quantity
            ).toFixed(2)}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
