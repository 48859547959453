import React, { useEffect, useState } from "react";
import Auth from "../auth/auth.connect";
import { UserSession } from "../auth/session.connect";

function VerificationPage() {
  const [ user, setUser ] = useState(null);
  const [ token, setToken ] = useState("");
  const [ btn, setBtn ] = useState("Verify Email");

  const auth = new Auth();

  useEffect(() => {
      if(UserSession) {
        console.log({ UserSession });
          setUser(JSON.parse(UserSession));
      } else {
        window.location = "/signup"
      }
  },[]);

  const verifyEmailPIN = async (e) => {
    setBtn("verifying Email");
    e.preventDefault();
    const result = await auth.verifyEmailPIN({
      user_id: user?.user_id,
      token
    });
     if(!result) {
      setBtn("Try again");
        alert("Unable to complete request.")
     } else {
       alert(result?.message);
       setBtn("verify Email");
       window.location = "/";
     }
  }

  const resendEmailCode = async (e) => {
      const result = await auth.resendEmailPIN(user?.user_id);

      if(!result) {
        alert("Unable to resend code.");
      } else {
        alert(result?.message);
      }
  }

  return (
    <div>
      <section class="bg-gray-50 dark:bg-white">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-black"
          >
            <h1 className="uppercase font-bold text-3xl text-black"> Dabinx </h1>
          </a>
          <div class="w-full bg-white rounded-none shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-black">
                Veify your email address
              </h1>
              <form class="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    for="otp"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    OTP
                  </label>
                  <input
                    type="text"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    name="otp"
                    id="otp"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-700 dark:text-black"
                    placeholder="123456"
                    required=""
                  />
                </div>

                <button
                  onClick={(e) => verifyEmailPIN(e)}
                  class="w-full text-white bg-black hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-none text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  { btn }
                </button>

                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  
                  <a
                    onClick={(e) => resendEmailCode(e)}
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Resend ?
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerificationPage;
