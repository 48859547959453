import React, { useEffect, useState } from "react";
import Auth from "../auth/auth.connect";
import { UserSession } from "../auth/session.connect";
import { AppNameOrigin } from "../app.config";
import Dropdown from "../components/DropDown";

function LoginPage() {
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ btn, setBtn ] = useState("Sign In");
    const [ disabled, setDisabled ] = useState(false);
    const auth = new Auth();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const [ redirectValue, setRedirectValue ] = useState(null);
    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectParam = searchParams.get('redirect');
      setRedirectValue(redirectParam !== null ? encodeURIComponent(redirectParam): null);
      console.log({ redirectParam });
    }, []);


    const Login = async (e) => {
      e.preventDefault();
      setDisabled(true)
      setBtn("Processing request...");
      const result = await auth.signIn({
        email,
        password
      });
      setDisabled(false);
      if(!result) {
        alert("Unable to Complete request.");
        setBtn("Try again");
      } else {
        alert(result?.message);
        sessionStorage.setItem("dabinx-user", JSON.stringify(result?.data));
        console.log(UserSession);
        setBtn("Done");
        setDisabled(false)
        window.location = decodeURIComponent(redirectValue);      }
    }




  return (
    <div>
      <section class="bg-gray-50 dark:bg-white pt-8">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-black"
          >
            <h1 className="uppercase text-3xl font-bold"> { 
            // AppNameOrigin 
            } </h1>
          </a>
          <div class="w-full bg-white rounded-none shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-black">
                Sign in to your account
              </h1>
              <div class="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent dark:border-gray-600 dark:placeholder-black dark:text-black"
                    placeholder="name@gmail.com"
                    required=""
                  />
                </div>
                
                <div>
                  <label
                    for="password"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-transparent dark:border-gray-600 dark:placeholder-black dark:text-black"                    required=""
                  />
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex items-start">

                    <div class="ml-3 text-sm">
                      
                    </div>
                  </div>
                  <a
                    href={`/forgot-password?redirect=${redirectValue}`}
                    class="text-sm font-medium text-primary-600 hover:underline dark:text-gray-800"
                  >
                    Forgot password?
                  </a>
                </div>

                <button
                  onClick={(e) => Login(e)}
                  disabled={disabled}
                  class="w-full text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-none text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  { btn }
                </button>

                <p class="text-sm font-light dark:text-gray-800">
                  Don’t have an account yet?{" "}
                  <a
                    href={redirectValue !== null ? `/signup?redirect=${redirectValue}` : "/signup" }
                    class="font-medium text-primary-600 hover:underline dark:text-gray-800"
                  >
                    Sign up
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoginPage;
